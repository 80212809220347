



















import Vue from "vue";
import Header from "@/components/layout/SearchHeader.vue";

export default Vue.extend({
  name: "PageNotFound",
  components: {
    Header,
  },
  metaInfo: {
    title: "Seite nicht gefunden - Einander Helfen",
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100";
        case "sm":
          return "150";
        case "md":
          return "250";
        default:
          return "300";
      }
    },
  },
});
